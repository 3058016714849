import { type EarlyAccessFeature, IntegrationModel, type KubernetesFeature } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";

import { useCustomerContext } from "../../Context/CustomerContext";
import { getCachingKeys } from "../../utils/cachingKeys";

export function useClusters() {
  const { customer } = useCustomerContext({ allowNull: true });

  const query = getCollection(IntegrationModel)
    .doc("fsk8s")
    .collection("fsk8sClusters")
    .where("customerRef", "==", customer?.ref);

  return useCollectionData(query, {
    caching: true,
    cachingKeys: getCachingKeys(customer?.id),
    idField: "id",
  });
}

export function useKubernetesFeatures() {
  const { customer } = useCustomerContext({ allowNull: true });

  const canCustomerAccessFeature = (feature: KubernetesFeature) => {
    if (!customer || !Array.isArray(customer.earlyAccessFeatures) || !feature.featureFlag) {
      return true;
    }

    return customer.earlyAccessFeatures.includes(feature.featureFlag as EarlyAccessFeature);
  };

  const query = getCollection(IntegrationModel).doc("fsk8s").collection("fsk8sFeatures");

  const [features, loading] = useCollectionData(query, {
    idField: "id",
  });

  return [features?.filter(canCustomerAccessFeature), loading] as const;
}
